import { Icon } from "@iconify/react";
import {
  addWeeks, eachDayOfInterval, endOfWeek, format,
  startOfWeek, subWeeks
} from "date-fns";
import fr from "date-fns/locale/fr";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SingleSelectButton from "./../../components/singleSelectButton/index";
import {
  fireErrorAlert,
  fireSuccessAlert
} from "./../../components/utils/Swal";
import { calendarClient } from "./../../network/lib/calendar";

function PlanningPage() {
  const { officeName } = useParams();
  const { calendarType } = useParams();

  const [date, setDate] = useState(new Date());

  const [calendarData, calendarDataSet] = useState([]);
  const [expertData, expertDataSet] = useState([]);
  const [expertIdData, expertIdDataSet] = useState(null);

  const startOfWeekDate = startOfWeek(date, { weekStartsOn: 1, locale: fr });
  const endOfWeekDate = endOfWeek(date, { weekStartsOn: 1, locale: fr });

  const formattedStartOfWeek = format(startOfWeekDate, "d", { locale: fr });
  const formattedEndOfWeek = format(endOfWeekDate, "d MMMM yyyy", {
    locale: fr,
  });

  const weekRange = `${formattedStartOfWeek} - ${formattedEndOfWeek}`;

  const daysWeek = eachDayOfInterval({
    start: startOfWeekDate,
    end: endOfWeekDate,
  });

  const daysDates = daysWeek.map((jour) =>
    format(jour, "EEE dd/MM yyyy", { locale: fr })
  );

  const addWeek = () => {
    const newDate = addWeeks(date, 1);
    setDate(newDate);
    makeCalendar(newDate);
  };

  const subtractWeek = () => {
    const newDate = subWeeks(date, 1);
    setDate(newDate);
    makeCalendar(newDate);
  };

  useEffect(() => {
    makeCalendar(date);
  }, [calendarType]);

  function addTiming(strDayData, hourData) {
    const dayObj = parseDateString(strDayData);

    const calendarTiming = {
      expertId: expertIdData,
      year: dayObj["year"],
      month: dayObj["month"],
      hour: hourData,
      day: dayObj["day"],
      calendarType: calendarType.toUpperCase(),
    };

    calendarClient.admin
      .addTiming(calendarTiming)
      .then((resp) => {
        fireSuccessAlert("L'horaire a été ajouté !").then(() => {
          makeCalendar(date);
        });
      })
      .catch((error) => {
        if (error.response.data.reason === "ExpertId is empty") {
          fireErrorAlert("Please select an expert");
        }
      });
  }

  const removeTiming = (calendarId) => {
    return (event) => {
      event.stopPropagation();

      if (calendarId === null) {
        return;
      }

      calendarClient.admin.deleteTiming(calendarId).then((resp) => {
        fireSuccessAlert("L'horaire a été supprimé !").then(() => {
          makeCalendar(date);
        });
      });
    };
  };

  const makeCalendar = (thisDate) => {
    const daysWeeks = eachDayOfInterval({
      start: startOfWeek(thisDate, { weekStartsOn: 1, locale: fr }),
      end: endOfWeek(thisDate, { weekStartsOn: 1, locale: fr }),
    });

    const calendar = {
      calendarType: calendarType.toUpperCase(),
      dates: daysWeeks.map((jour) =>
        format(jour, "dd/MM/yyyy", { locale: fr })
      ),
    };

    calendarClient.admin.postCalendar(calendar).then((resp) => {
      calendarDataSet(resp.data.calendar);
      expertDataSet(resp.data.experts);
    });
  };

  function extractDayAndDate(dateString) {
    const [dayOfWeekStr, datePart] = dateString.split(" ", 2); // Split into two parts, ignoring the year
    return `${dayOfWeekStr} ${datePart}`;
  }

  function parseDateString(dateString) {
    const [dayOfWeekStr, datePart, yearStr] = dateString.split(" ");
    const [dayStr, monthStr] = datePart.split("/");

    const day = parseInt(dayStr);
    const month = parseInt(monthStr);
    const year = parseInt(yearStr);

    return {
      day,
      month,
      year,
    };
  }

  return (
    <div id="myPlanning">
      <h2>
        Planning{" "}
        {calendarType.toUpperCase() === "CLASSIC" ? "Classique" : "Rendez-vous"}
      </h2>
      <div className="dateBar">
        <h4>{weekRange}</h4>
        <div className="navigation">
          <button className="btnGreen-arrow" onClick={subtractWeek}>
            <Icon icon="material-symbols:chevron-left" />
          </button>
          <button className="btnGreen-arrow" onClick={addWeek}>
            <Icon icon="material-symbols:chevron-right" />
          </button>
        </div>
      </div>

      <div className="content">
        <div className="emplois">
          <div className="badges">
            <SingleSelectButton
              expertData={expertData}
              expertIdDataSet={expertIdDataSet}
            ></SingleSelectButton>
          </div>
          <div className="emploi">
            <div className="part min">
              <div className="top"></div>
              {[0, 1, 2, 3, 4, 5, 6].map((elem) => {
                return (
                  <div key={elem}>
                    <div className="top">{extractDayAndDate(daysDates[elem])}</div>
                  </div>
                );
              })}
            </div>
            {[
              "00",
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23",
            ].map((elem) => {
              return (
                <div key={elem} className="part">
                  <div className="top">{elem} h</div>
                  {[
                    "MONDAY",
                    "TUESDAY",
                    "WEDNESDAY",
                    "THURSDAY",
                    "FRIDAY",
                    "SATURDAY",
                    "SUNDAY",
                  ].map((day, index) => (
                    <div key={day}>
                      <div
                        onClick={() =>
                          addTiming(daysDates[index], parseInt(elem))
                        }
                      >
                        {calendarData[day] && calendarData[day][parseInt(elem)]
                          ? calendarData[day][parseInt(elem)].map(
                              (calendar, index) => (
                                <span
                                  key={index}
                                  style={{ backgroundColor: calendar["color"] }}
                                  onClick={removeTiming(calendar["calendarId"])}
                                  className="expert-badge"
                                >
                                  {calendar["expertName"]}
                                </span>
                              )
                            )
                          : null}
                      </div>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlanningPage;
